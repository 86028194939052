import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FilterButton, Flex, MoreBar, TableWidget, VStack } from '@revolut/ui-kit'
import {
  getDepartmentTimeOffCalendar,
  getEmployeesTimeOffCalendar,
  getRequestsOfReportsCalendar,
  getTeamTimeOffCalendar,
} from '@src/api/timeOff'
import { useGetTeam } from '@src/api/teams'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TimeOffCalendar } from '@src/features/TimeOffCalendarTable/TimeOffCalendar'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { WeekMonthTabs } from '@src/features/TimeOffCalendarTable/constants'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { ApprovalsWidget } from './ApprovalsWidget'

type CalendarType = 'team' | 'department' | 'reports'

export const TimeManagement = () => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const { data: teamData } = useGetTeam(user.team.id)
  const [calendarType, setCalendarType] = useState<CalendarType | undefined>('team')

  const canViewTeamRequestsPolicy = !!teamData?.field_options.permissions?.includes(
    PermissionTypes.ViewTimeOffRequestPolicy,
  )
  const canViewTimeOffPolicies = permissions.includes(PermissionTypes.ViewTimeOffPolicies)
  const canViewTimeOffPreferences = permissions.includes(
    PermissionTypes.ViewTimeOffPreferences,
  )

  const { tabBar: periodTabBar, currentTab: periodCurrentTab } = useTabBarSwitcher({
    tabs: [WeekMonthTabs.Week, WeekMonthTabs.Month],
    defaultTab: WeekMonthTabs.Month,
    highlightSelected: false,
  })

  const getTableApi = () => {
    return calendarType === 'team'
      ? getTeamTimeOffCalendar(user.team.id)
      : calendarType === 'reports'
      ? getRequestsOfReportsCalendar(user.id)
      : teamData?.department?.id && calendarType === 'department'
      ? getDepartmentTimeOffCalendar(teamData.department.id)
      : getEmployeesTimeOffCalendar
  }

  return (
    <VStack space="s-16">
      <ApprovalsWidget />
      <TableWidget>
        <TableWidget.Actions>
          <MoreBar>
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL, {
                employeeId: user.id,
              })}
              use={InternalLink}
              useIcon="Plus"
            >
              Request time off
            </MoreBar.Action>
            {canViewTimeOffPolicies && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.POLICIES)}
                use={InternalLink}
                useIcon="Pencil"
              >
                Manage policies
              </MoreBar.Action>
            )}
            {canViewTimeOffPreferences && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.SETTINGS.TIME_OFF.GENERAL)}
                use={InternalLink}
                useIcon="Gear"
              >
                Settings
              </MoreBar.Action>
            )}
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Filters>
          <FilterButton
            active={calendarType === 'team'}
            onClick={() => setCalendarType(calendarType !== 'team' ? 'team' : undefined)}
          >
            My team
          </FilterButton>
          {teamData?.department?.id && (
            <FilterButton
              active={calendarType === 'department'}
              onClick={() =>
                setCalendarType(calendarType !== 'department' ? 'department' : undefined)
              }
            >
              My department
            </FilterButton>
          )}
          <FilterButton
            active={calendarType === 'reports'}
            onClick={() =>
              setCalendarType(calendarType !== 'reports' ? 'reports' : undefined)
            }
          >
            My reports
          </FilterButton>
        </TableWidget.Filters>
        <TableWidget.Table>
          <VStack gap="s-16">
            <Flex>{periodTabBar}</Flex>
            <TimeOffCalendar
              data={user}
              hideFilterButton
              hidePolicy={calendarType === 'team' ? canViewTeamRequestsPolicy : undefined}
              tableName={TableNames.PeopleTimeOffCalendar}
              tableOptions={{ disableQuery: true }}
              weekMonthTab={periodCurrentTab || 'Month'}
              getItems={getTableApi()}
              key={calendarType}
            />
          </VStack>
        </TableWidget.Table>
      </TableWidget>
    </VStack>
  )
}
